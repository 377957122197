<template>
  <!-- <div v-if="action">
        <div
          class="fixed inset-0 w-full h-full bg-black/60 backdrop-blur-sm z-[22]"
          @click="$emit('closeModalShare')"
        ></div>
        <div
          class="w-full max-w-[358px] flex flex-col gap-6 md:max-w-[521px] z-[23] h-fit bg-white dark:bg-neutral-700 dark:border-none border-t box-border border-neutral-200 p-6 md:p-8 fixed inset-0 m-auto rounded"
        >
          <div @click="$emit('closeModalShare')" class="absolute top-4 right-4 md:top-6 md:right-6">
            <svg
              width="20"
              class="text-neutral-800 dark:text-white cursor-pointer"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5 15L15 5M5 5L15 15"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <p
            class="font-semibold text-xl leading-none text-neutral-800 dark:text-neutral-200 capitalize"
          >Actions Modified</p>
          <div class="flex flex-col gap-3">
            <div
              class="font-semibold text-sm leading-1 text-neutral-600"
            >Would you like to make these changes to FUTURE scheduled webinars, or All?</div>
            
          </div>
          <div class="w-full flex justify-end gap-3">
            <ReusableUIButtonsButtonSecondary size="medium" text="Future" />
            <ReusableUIButtonsButtonSecondary size="medium" text="All"  @click="$emit('installTemplate'),action = true"/>
          </div>
        </div>
      </div> -->
  <div>
    <div
      class="fixed inset-0 w-full h-full bg-black/60 backdrop-blur-sm z-[999999]"
      @click="$emit('close')"
    ></div>
    <div
      class="w-full max-w-[358px] flex flex-col gap-6 md:max-w-[521px] z-[9999999] h-fit bg-white dark:bg-neutral-800 dark:border-none border-t box-border border-neutral-200 p-6 md:p-8 fixed inset-0 m-auto rounded"
    >
      <div
        @click="$emit('close')"
        class="absolute top-4 right-4 md:top-6 md:right-6"
      >
        <svg
          width="20"
          class="text-neutral-800 dark:text-white cursor-pointer"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5 15L15 5M5 5L15 15"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <p
        class="font-semibold text-xl leading-none text-neutral-800 dark:text-neutral-200 capitalize"
      >
        Are you sure?
      </p>
      <div class="flex flex-col gap-3">
        <div
          class="font-semibold text-sm text-neutral-600 dark:text-neutral-400"
          v-if="source && source == 'timeline'"
        >
          There are unsaved changes on this timeline. <br />
          Would you like to save first before exit?
        </div>
        <div
          class="font-semibold text-sm text-neutral-600 dark:text-neutral-400"
          v-else-if="source && source == 'schedule'"
        >
          Exiting now will result in the loss of unsaved changes <br />
          for this webinar. Are you certain you wish to proceed? <br />
        </div>

        <div
          v-else-if="source == 'confirm-password'"
          class="font-semibold text-sm text-neutral-600 dark:text-neutral-400"
        >
          <p class="pb-2">Please enter your password to update the email</p>
          <ReusableUIInput
            place-holder="Enter your password"
            width="100%"
            v-model="password"
          />
          <p class="text-red-500 text-sm">{{ errorMessage }}</p>
        </div>
        <div
          class="font-semibold text-sm text-neutral-600 dark:text-neutral-400"
          v-else
        >
          Would you like to update timezone? All currently scheduled webinars
          will still occure in the previous timezones that are in which it was
          when scheduled
        </div>
      </div>
      <div class="w-full flex justify-end gap-3">
        <ReusableUIButtonsButtonTertiary
          size="medium"
          :text="source && source == 'schedule' ? 'Proceed' : 'Cancel'"
          @click="$emit('cancel', route)"
          :theme="source && source == 'schedule' ? 'red' : 'blue'"
        />
        <ReusableUIButtonsButtonSecondary
          v-if="source && source == 'timeline'"
          size="medium"
          text="Save"
          @click="$emit('save', route)"
          :loading="loading"
        />
        <ReusableUIButtonsButtonSecondary
          v-else-if="source && source == 'schedule'"
          size="medium"
          text="Stay"
          @click="$emit('close')"
          :loading="loading"
        />
        <ReusableUIButtonsButtonSecondary
          v-else
          size="medium"
          text="Confirm"
          @click="handleClose"
          :loading="loading"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { useAuthStore } from "~~/store/auth";

// Consts
const authStore = useAuthStore();
const props = defineProps({
  title: String,
  sharedCode: String,
  loading: Boolean,
  source: String,
  route: String,
});
const action = ref(false);
const password = ref("");
const errorMessage = ref("");
const emit = defineEmits(["cancel", "close", "confirm"]);
const handleClose = async () => {
  if (props.source == "timeline") {
    emit("confirm");
  } else if (props.source == "confirm-password") {
    const paload = {
      password: password.value?.toString(),
    };
    const response = await authStore.checkpassword(paload, "", true);
    if (response.success == "true") {
      emit("confirm");
    } else {
      errorMessage.value = "Password is incorrect";
    }
  } else emit("confirm", props.route);
};
</script>
